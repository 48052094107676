






import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Heading extends Vue {
  @Prop({ default: 'h3' })
  private readonly level!: string;
}
