






import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class AR extends Vue {
  @Prop({ required: true })
  private readonly ratio!: number;
}
