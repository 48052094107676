import Vue from 'vue';

import Deferrable from 'client-website-ts-library/components/Deferrable.vue';
import OptimisedImage from 'client-website-ts-library/components/OptimisedImage.vue';

import Page from './Layout/Page.vue';
import ContentContainer from './Layout/ContentContainer.vue';
import ContentRow from './Layout/ContentRow.vue';
import LazyImage from './UI/LazyImage.vue';
import Card from './Layout/Card.vue';
import Cards from './Layout/Cards.vue';
import Row from './Layout/Row.vue';
import Column from './Layout/Column.vue';
import SiteMasthead from './SiteMasthead.vue';
import SectionTitle from './Common/SectionTitle.vue';
import SectionSubtitle from './Common/SectionSubtitle.vue';
import Button from './UI/Button.vue';
import ButtonGroup from './UI/ButtonGroup.vue';
import LinkButton from './UI/LinkButton.vue';
import Icon from './UI/Icon.vue';
import AR from './UI/AR.vue';
import RichText from './Common/RichText.vue';
import Heading from './Common/Heading.vue';

Vue.component('Page', Page);
Vue.component('ContentContainer', ContentContainer);
Vue.component('ContentRow', ContentRow);
Vue.component('LazyImage', LazyImage);
Vue.component('Card', Card);
Vue.component('Cards', Cards);
Vue.component('Row', Row);
Vue.component('Column', Column);
Vue.component('SiteMasthead', SiteMasthead);
Vue.component('SectionTitle', SectionTitle);
Vue.component('SectionSubtitle', SectionSubtitle);
Vue.component('Button', Button);
Vue.component('ButtonGroup', ButtonGroup);
Vue.component('LinkButton', LinkButton);
Vue.component('Icon', Icon);
Vue.component('AR', AR);
Vue.component('RichText', RichText);
Vue.component('Heading', Heading);
Vue.component('Deferrable', Deferrable);
Vue.component('OptimisedImage', OptimisedImage);
