






import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Column extends Vue {
  @Prop()
  private readonly width!: number;

  @Prop()
  private readonly mdWidth!: number;

  @Prop()
  private readonly smWidth!: number;

  @Prop({ default: 0.5 })
  private readonly margin!: number;

  @Prop({ default: 0 })
  private readonly padding!: number;

  @Prop({ default: 'transparent' })
  private readonly backgroundColor!: string;
}
